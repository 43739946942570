body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  width: 100%;
  min-height: 100%;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}

.banner {
  border-style: solid;
  border-color: red;
  width: 50%;
  display: block;
  margin-right: 40%;
  text-align: left;
}

.bannerDescription {
  font-size: 15px;
  border-style: solid;
  border-color: black;
  display: block;
}
.therapistButton {
  margin-top: 0;
  border-color: black;
  border-style: solid;
  padding: 0;
  height: 10%;
}
.ascredescrip {
  font-size: 15px;
  display: block;
  border-style: solid;
  border-color: black;
  text-align: left;
}

.descrip {
  margin-right: 40%;
  width: 50%;
}

.signup{
  border-color: black;
  border-style: solid;
  margin-left: 40%;
  width: 50%;
}

.signupParagraph {
  font-size: 15px;
}
.signupTitle {
  font-size: 2rem;
}

#Home {
  background-color: #cfd6d1;
  border-style: solid;
  border-color: black;
  display: flex;
  height: 500px;
}
#About {
  background-color: #f9e6d2;
  text-align: left;
  border-color: black;
  border-style: solid;
}
#WhyAscre {
  background-color: #e0e7ca;
  height: 500px;
}
#Contact {
  background-color: #d7ecf6;
}
footer {
  height: 200px;
  background: #505152;
  color: white;
}

.AccountPage {
  border-style: solid;
  border-color: black;
}
.ScheduleTab {

  width: 100%;
  height: 100%;
}

.AccountInformation {
  border-style: solid;
  border-color: black;
  width: 100%;
  height: 100%;
}

.nav-link-dropdown{
  color:black;
}




.join-room {
  max-width: 380px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  padding: 15px;
}
.join-room .logo {
  width: 70px;
}
.join-room input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #fff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
  margin-top: 15px;
}
.join-room .join-channel {
  color: #fff;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714;
  box-sizing: border-box;
  overflow: visible;
  margin-top: 10px;
}
.join-room .join-channel.disabled {
  background-color: #d9d9d9;
  box-shadow: none;
}
.room {
  padding-top: 100px;
}

.user {
  border-style: solid;
  --un-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--un-border-opacity));
  border-width: 1px;
  box-sizing: border-box;
  width: 100rem;
  height: 100rem;
}
.user-name {
  --un-text-opacity: 1;
  color: rgba(255, 255, 255, var(--un-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  --un-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--un-bg-opacity));
  grid-gap: 0.25rem;
  gap: 0.25rem;
  align-items: center;
  display: inline-flex;
  z-index: 2;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
}
.control {
  --un-text-opacity: 1;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --un-bg-opacity: 1;
  /*background-color: rgba(33, 36, 44, var(--un-bg-opacity));*/
  grid-gap: 0.75rem;
  
  justify-content: center;
  align-items: center;
  display: flex;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
}
.left-control {
  
  
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  align-items: center;
  flex: 1 1 0%;
  display: flex;
  height: 100%;
}

.TimerApp {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-top: 50px;
}



.row {
  display: flex;
  justify-content: center;
}

.TimerReset {
  width: 40%;
  length: 10px;
  margin: 0 5px;
  font-size: 1em;
  display: inline-block;
  cursor: pointer;
}

.TimerControlButton {
  width: 40%;
  length: 10px;
  margin: 0 5px;
  font-size: 1em;
  display: inline-block;
  cursor: pointer;
}



.VideoApp {
  width: 100%;
  height: 1000px;
}
.VideoBox {
  border-style: solid;
  display: inline-block;
  width: 100%;
  height: 65%;
}

.DoctorVideo {
  border-style: solid;
  display: block;
  width: 25%;
  height: 20%;
  margin: 5px;
}

.GuestVideo {
  border-style: solid;
  width: 90%;
  height: 60%;
  display: inline-block;
  margin: 5px;
}

.VideoButtons {
  border-style: solid;
  height: 10%;
  margin: 5px;
}

.DoctorTools {
  border-style: solid;
  display: inline-block;
  width: 100%;
  height: 300px;
  margin-top: 10px;
}

.DoctorNotes {
  border-style: solid;
  display: inline-block;
  width: 45%;
  height: 80%;
  margin: 5px;
  vertical-align: top;
}
.SubTools {
  border-style: solid;
  display: inline-block;
  width: 45%;
  height: 80%;
  margin: 5px;
  vertical-align: top;
}

.TimerBox {
  border-style: solid;
  display: inline-block;
  width: 90%;
  height: 60%;
  margin: 5px;
}
.Timer {
  border-style: solid;
  display: inline-block;
  text-align: center;
  font-size: 200%;
  height: 50%;
  margin: 2px;
}

.AppointmentButton {
  border-style: solid;
  display: block;
  width: 90%;
  margin: 5px;
}