

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-link {
  color:black;
}



/* for claendars */
.reservation {
  font-family: Arial, sans-serif;
  width: 400px;
  margin: 20px auto;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
}

h2 {
  color: green;
  font-weight: bold;
  margin-bottom: 20px;
}

.reservation-details {
  background-color: #FFF;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.reservation-details input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #CCC;
  border-radius: 5px;
}

.month-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.month-navigation button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;

}

.ModalCalendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  background-color: #efefef;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.ModalCalendar button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ModalCalendar button:active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px; /* Adjusted this to match the button size */
  height: 30px; /* Adjusted this to match the button size */
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.ModalCalendar button.selected {
  background-color: green;
  color: white;
}

.time-slots {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.time-slots button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: transparent;
  transition: background-color 0.3s;
}

.time-slots button:hover {
  background-color: #ddd;
}

.reserve-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border: none;
  background-color: green;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reserve-button:hover {
  background-color: darkgreen;
}

.time-slots button {
  padding: 2px 5px; /* Reduced padding */
  font-size: 13px; /* Reduced font size */
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: transparent;
  transition: background-color 0.3s;
}

.time-slots {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr)); /* This will make the grid more responsive */
  gap: 5px;
}

.calendar button.selected {
  background-color: green; /* This will be the circle shade */
  color: white; /* Makes the date number stand out */
  border-radius: 50%; /* Ensures the shape is circular */
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar button {
  width: 30px;
  height: 30px;
  border-radius: 50%; /* Makes the button shape circular */
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar button:hover {
  background-color: rgba(0, 128, 0, 0.1); /* Light shade of green on hover for better user feedback */
}

.calendar button.selected {
  background-color: green;
  color: white;
}

.time-slots button.selected {
  background-color: green;
  color: white;
}

.button.disabled {
  background-color: #d3d3d3;  /* or any other color you'd like for disabled days */
  cursor: not-allowed;
}


/*scheduler css*/
.therapist_scheduler {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  max-width: 800px;
}

.therapist_title {
  text-align: center;
}

/*Client list*/

/* Styles for the overall container of the client list */
.therapist-clientlist {
  width: 100%; /* Ensures full width */
  flex-grow: 1; /* Allows the container to expand to fill the space */
  padding: 20px;
  overflow: hidden; /* Handle overflow */
}


/* Container specific to DataGrid for more control over its styling */
.data-grid-container {
  width: 100%; /* Full width */
  height: 100%; /* Full height to stretch within flex container */
  overflow-x: auto; /* Handle horizontal scroll */

}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .data-grid-container {
    width: 100%;
    padding: 10px;
  }
}


/*therapist review comments*/

.ratings-summary {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
}

.stars-summary {
  display: flex;
  align-items: center;
}

.rating-value {
  font-size: 24px;
  margin-left: 10px;
}

.recommendation {
  font-weight: bold;
  margin-top: 10px;
}

.review-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
}

.read-more {
  color: blue;
  cursor: pointer;
}

.helpful-count {
  font-weight: bold;
  margin-right: 10px;
}

.recommendation-icon {
  float: right;
}



/*week view for therapist scheduler*/
.therapist_week-view {
  display: grid;
  grid-template-columns: 50px repeat(7, 1fr); /* 50px for the hour labels, 1fr for each day */
  font-family: Arial, sans-serif;
  max-height: 470px;
  overflow-y: auto;
}


.therapist_header-row {
  position: sticky;
  display: contents; /* Makes the header part of the same grid as the content */
  top: 0; /* Adjust as needed based on your layout */
  background: white; /* Or any other background color that matches your design */
  z-index: 10; /* En
}

.therapist_day-column-header {
  text-align: center;
  padding: 10px;
  border-right: 1px solid #ddd; /* Vertical line for the header */
}

.therapist_grid {
  display: contents; /* Ensures the grid uses the same columns as the header */
}

.therapist_hour-row {
  display: contents; /* This will make each hour-row part of the same grid */
}

.therapist_hour-cell {
  border-top: 1px solid #ddd; /* Horizontal line for each cell */
  height: 15px; /* Half the height for half-hour slots */
  padding: 0; /* Removes padding to ensure alignment */
  position: relative; /* Needed for absolute positioning of the pseudo-element */
}
.therapist_hour-row:not(:last-child) .therapist_hour-cell {
  border-right: 1px solid #ddd; /* Vertical line for each cell except last in the row */
}

.therapist_hour-label {
  grid-column: 1; /* Places the hour label in the first column */
  text-align: right;
  padding-right: 5px;
  border-bottom: 1px solid #ddd; /* Horizontal line for each cell */
  height: 0px; /* Half the height for half-hour slots */
}

.therapist_mid-hour-cell {
  position: relative; /* Needed for absolute positioning of the pseudo-element */
  border-right: 1px solid #ddd;
  height: 30px; /* Height for one hour */
  
}

.therapist_mid-hour-cell::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%; /* Position at the middle of the cell */
  border-top: 1px dashed #ddd; /* Dotted line */
  z-index: 1;
}

/* Adjust the .therapist_hour-cell to not have a border when it's a mid-hour cell */
.therapist_hour-cell:not(.therapist_mid-hour-cell) {
  border-right: 1px solid #ddd;
}

.therapist_hour-cell.selected {
  /* Rounded corners */
  border-radius: 8px; /* Adjust as needed */
  
  /* Light green background */
  background-color: rgba(144, 238, 144, 0.3); /* Light green with transparency */
  
  /* Ensure the content inside the cell is positioned above the background */
  position: relative;
  z-index: 1;
}

.therapist_selected-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* This ensures the block itself is not adding any additional color overlay */
  background: transparent;
  /* Rounded corners to match the cell */
  border-radius: 8px;
  /* This will ensure the selected block is under the cell content but above the cell's background */
  z-index: 0; 
}

/* You might also want to add some padding to the cell to prevent the content from touching the borders */
.therapist_hour-cell {
  padding: 5px; /* Adjust as necessary */
  /* ... other styles ... */
}


.therapist_event-block {
  /* Styles to make the event details visible inside the cell */
  color: white; /* Text color */
  padding: 2px; /* Some padding */
  overflow: hidden; /* Prevent text overflow */
  white-space: nowrap; /* Prevent wrapping and keep the text on one line */
  text-overflow: ellipsis; /* Add an ellipsis if the text is too long */
  font-size: 0.5em; /* Smaller font size to fit into the cell */
}

.therapist_session_event {
  font-size: 0.8em;
  background-color: darkgreen;
  border-radius: 8px;
  color: white;
  text-align: center;
  padding:0px;
  margin: 0px;
  cursor: grab;
}

.therapist_schedule_selected {
  background-color: lightgreen;
}

.therapist_hour-cell.hide-border {
  border-top: 1px solid transparent; /* Makes the line transparent */
}

.hidden {
  border-right:#FFF;
  border-top:#FFF;
  border-bottom:#FFF;
  color:#fff;
}

.therapist_hour-cell.selected,
.therapist_hour-cell.selected + .therapist_hour-cell {
  background-color: rgba(144, 238, 144, 0.3); /* Light green with transparency */
}

.arrow-button-schedule-dates {
  background: transparent; /* No background */
  border: none; /* No borders */
  cursor: pointer; /* Cursor to pointer to indicate it's clickable */
  padding: 0; /* No padding */
  margin: 0; /* No margins */
  font-weight: bold; /* Make the arrow bold */
  font-size: 2em; /* Increase the size of the arrow */
}


.therapist_day-column-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.therapist_day-column-header > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}
